.font-befa {
    font-family: 'Montserrat';
  }

.fixed-header {
    position: fixed;
    top: 0;
    width: 100%;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 10px 0;
  }

  .hero-section {
    background: #fff;
    border-radius: 10px;
    margin: 61px auto;
  }

  .hero-content-job {
    position: relative;
    width: 100%;
    height: 240px;
    overflow: hidden;
  }

  .card-detail {
    border: 1px solid #ccc;
    padding: 24px;
    height: 104px;
    margin-bottom: 10px;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    position: relative;
    border: 0px;
    box-shadow: none;
  }

  .btn-jd {
    border-radius: 8px; /* Membuat tombol berbentuk oval */
    padding: 20px 32px 20px 32px; /* Menambahkan padding agar tombol lebih besar dan terlihat seperti oval */
    font-size: 14px; /* Sesuaikan ukuran font */
    height: 62px;
    width: 143px;
    background-color: #0296D2;
  }

  .horizontal-line {
    width: 1120px;
    height: 1px;
    background-color: #E8E9EB;
    margin: 0 auto; /* Add this line to center the line horizontally */
  }

  .job-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 20px; /* Sesuaikan dengan kebutuhan */
    top: 50%;
    transform: translateY(-50%);
  }

  .card-content {
    border: 1px solid #ccc;
    padding: 24px;
    height: auto; /* Updated height */
    width: 274px; /* Updated width */
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    position: relative;
    border: 0px;
    box-shadow: none;
}

.card-img-top {
    height: 120px; /* Updated image height */
    width: 274px; /* Updated image width */
}

.jd-icon {
  height: 14px;
  width: 14px;
}

.jd-sub {
  font-size: 12px;
  font-weight: 400;
  color: #737E86;
  margin-left: 2px;
}
.jd-container {
  display: flex;
  justify-content: space-between; /* Adjust space between the divs */
  gap: 20px;
}


.jd-container > :first-child {
  margin-right: auto;
}

.jd-container > :last-child {
  margin-left: auto;
}