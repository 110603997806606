/* Footer.css */
.footer {
    background-image: url('FooterBG.png'); 
    background-size: cover;  /* Ensure the background covers the entire footer */
    background-position: center;  /* Center the background image */
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
}

.logo {
    font-size: 24px;
    font-weight: bold;
}

.tagline {
    font-size: 16px;
    color: #6c757d; /* Gray color */
}

.social-media {
    margin: 10px 0;
}

.social-media p {
    display: inline-block;
    margin: 0;
    margin-right: 5px;
    font-size: 14px;
    color: #6c757d;
}

.social-media a {
    text-decoration: none;
}

.social-media a:hover {
    opacity: 0.7; /* Slight transparency on hover */
}

.linkedin-icon {
    height: 24px; /* Adjust the size as needed */
}

.copyright {
    font-size: 14px;
    color: #6c757d;
}

.befaLogo-icon {
    width: 90px;
    height: 63.64px;
    gap: 0px;
    opacity: 0px;
}

.bewareFraud{
    width: 70%;
    gap: 0px;
    border-radius: 8px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
}