.font-befa {
  font-family: 'Montserrat' !important;
}

p{
  font-family: 'Montserrat' !important;
}

h5{
  font-family: 'Montserrat' !important;
}
h4{
  font-family: 'Montserrat' !important;
}
h3{
  font-family: 'Montserrat' !important;
}
h2{
  font-family: 'Montserrat' !important;
}
h1{
  font-family: 'Montserrat' !important;
}

span{
  font-family: 'Montserrat' !important;
}

body{
  font-family: 'Montserrat' !important;
}

.bg-login{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Ensure the background stays behind other elements */
}

.background-image {
  background-size: 'cover';
  background-position: 'center';
  height: '100vh';
  width: '100vw';
}

.custom-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
  padding-bottom: 20px;
}

.textAlign {
  text-align: left;
}

.bottomPad {
  padding-bottom: "8px"
}

.hdInput {
  border: 0;
  background-color: #F3F4F5;
  color: #404041;
  border-radius: 8px;
  font-family: 'Montserrat';
}

.hdInput::placeholder {
  color: #737E86; 
}

.hdInput:focus {
  background-color: #F3F4F5; /* Set the same background color on focus */
  outline-color: #a0bfe1;
}

.hdErrorInput {
  border: 1px solid #FFB8AD; /* Add border with the new color */
  background-color: #FFDBD6;
  color: #E33B21;
  border-radius: 8px;
  pointer-events: none; 
  font-family: 'Montserrat';
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .center {
  display: flex;
  align-items: center;
  gap: 10px; /* Sesuaikan jarak sesuai kebutuhan */
}

.header img {
  height: 40px;
}

.header h1 {
  margin: 0;
  font-size: 20px;
}



.header-m {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.header-m .center {
  display: flex;
  align-items: center;
  gap: 10px; /* Sesuaikan jarak sesuai kebutuhan */
}

.header-m img {
  height: 40px;
}

.header-m h1 {
  margin: 0;
  font-size: 20px;
}

.login-page2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

/* Global Styles */
.career-page {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  background: #f0f8ff; /* Light blue background */
  padding: 20px;
}

.main {
  margin-top: 80px; /* Adjust according to the header height */
}

.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header-content h1 {
  font-size: 1.5em;
  color: #0296D2;
}

.login-button {
  background: #0296D2;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.login-button:hover {
  background: #00374E;
}

/* Hero Section Styles */
.hero-section {
  background: #fff;
  border-radius: 10px;
  margin: 61px auto;
}

.shadow-box {
  position: absolute;
  top: 32%; /* Atur posisi vertikal sesuai dengan gambar */
  right: 0; /* Atur posisi horizontal sesuai dengan gambar */
  transform: translateY(-50%);
  background-color: #0296D2; /* Warna shadow */
  width: 314px; /* Sesuaikan lebar dengan gambar overlay */
  height: auto; /* Sesuaikan tinggi dengan gambar overlay */
  z-index: 0; /* Pastikan kotak shadow berada di belakang gambar overlay */
}

.hero-content {
  position: relative;
  width: 100%;
  height: 540px;
  overflow: hidden;
}

.hero-content .background-img {
  width: 100%;
  height: 100%;
  z-index: 0;
}


.background-img-header {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.background-img-header-m2 {
  width: 100vw;
  height: 9vh;
  object-fit: cover;
  z-index: 0;
}


.hero-content .overlay-box {
  position: absolute;
  right: 324px;  /* Same as the image to align */
  top: 34%;  /* Same as the image to vertically align */
  transform: translateY(-50%);
  width: 240px;  /* Adjust width as needed */
  height: 240px; /* Adjust height as needed */
  background-color: #0296D2;
  border-radius: 8px;
  z-index: 1;  /* Ensure the box is behind the image */
}

.hero-content .overlay-img {
  position: absolute;
  right: 314px;  /* Adjust this value to move the image slightly to the left */
  top: 32%;  /* Vertically center the image */
  transform: translateY(-50%);
  width: 240px;  /* Adjust width as needed */
  height: 240px; /* Adjust height as needed */
  z-index: 2;   /* Ensure the overlay image is above the background image */
}

.hero-content .overlay-box2 {
  position: absolute;
  right: 180px;  /* Same as the image to align */
  top: 65%;  /* Same as the image to vertically align */
  transform: translateY(-50%);
  width: 240px;  /* Adjust width as needed */
  height: 240px; /* Adjust height as needed */
  background-color: #0296D2;
  border-radius: 8px;
  z-index: 3;  /* Ensure the box is behind the image */
}

.hero-content .overlay-img2 {
  position: absolute;
  right: 170px;  /* Adjust this value to move the image slightly to the left */
  top: 63%;  /* Vertically center the image */
  transform: translateY(-50%);
  width: 240px;  /* Adjust width as needed */
  height: 240px; /* Adjust height as needed */
  z-index: 4;   /* Ensure the overlay image is above the background image */
}

.hero-content h1, .hero-content p {
  position: absolute;
  left: 65px;  /* Adjust this value as needed */
  text-align: left;
  z-index: 2;   /* Ensure text is above both images */
}

.hero-content h1 {
  top: 32%;
  transform: translateY(-50%);
  font-size: 4.2em;
  color: #00374E;
}

.hero-content p {
  top: calc(40% + 2.5em);  /* Adjust this value as needed to position below h1 */
  font-size: 1.4em;
  color: #00374E;
}

.hero-images {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.hero-image {
  border-radius: 10px;
  width: 45%;
  margin: 0 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



.hero-content-m {
  position: relative;
  width: 100%;
  height: 580px;
}



.hero-content-m .background-img {
  height: auto;
  width: 100%;
  z-index: 0;
  position: absolute;
}


.background-img-header-m {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
}


.hero-content-m .overlay-box {
  position: absolute;
  left: 50px;  /* Same as the image to align */
  top: 57%;  /* Same as the image to vertically align */
  transform: translateY(-50%);
  width: 150px;  /* Adjust width as needed */
  height: 150px; /* Adjust height as needed */
  background-color: #0296D2;
  border-radius: 8px;
  z-index: 1;  /* Ensure the box is behind the image */
}

.hero-content-m .overlay-img {
  position: absolute;
  left: 60px;  /* Adjust this value to move the image slightly to the left */
  top: 55%;  /* Vertically center the image */
  transform: translateY(-50%);
  width: 150px;  /* Adjust width as needed */
  height: 150px; /* Adjust height as needed */
  z-index: 2;   /* Ensure the overlay image is above the background image */
}

.hero-content-m .overlay-box2 {
  position: absolute;
  right: 60px;  /* Same as the image to align */
  top: 74%;  /* Same as the image to vertically align */
  transform: translateY(-50%);
  width: 150px;  /* Adjust width as needed */
  height: 150px; /* Adjust height as needed */
  background-color: #0296D2;
  border-radius: 8px;
  z-index: 3;  /* Ensure the box is behind the image */
}

.hero-content-m .overlay-img2 {
  position: absolute;
  right: 50px;  /* Adjust this value to move the image slightly to the left */
  top: 72%;  /* Vertically center the image */
  transform: translateY(-50%);
  width: 150px;  /* Adjust width as needed */
  height: 150px; /* Adjust height as needed */
  z-index: 4;   /* Ensure the overlay image is above the background image */
}

.hero-content-m h1, .hero-content-m p {
  position: absolute;
  width:100%;  /* Adjust this value as needed */
  text-align: left;
  z-index: 2;   /* Ensure text is above both images */
}

.hero-content-m h1 {
  top: 15%;
  color: #00374E;
  text-align: center;
}

.hero-content-m p {
  top: calc(40% + 2.5em);  /* Adjust this value as needed to position below h1 */
  font-size: 1.4em;
  color: #00374E;
}

.edit-link {
  display: block;
  margin-top: 20px;
  color: #0296D2;
  text-decoration: none;
  font-weight: bold;
}

.edit-link:hover {
  text-decoration: underline;
}

.vacancy-list-container {
  position: relative;
  text-align: center;
  padding: 50px;
  background-color: white;
}

/* Vacancy List Container */
.vacancy-list-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Semi-circle styling */
.semi-circle-container {
  position: absolute;
  left: 0;
  top: 0;
}

.semi-circle {
  width: 75px;
  height: 166px;
}

/* Title and Subtitle Styling */
.title {
  font-family: 'Montserrat';
  font-size: 40px;
  font-weight: 700;
  color: #282828;
}

.subtitle {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  color: #7B7B7B;
}

/* Vacancy List Section */
.vacancy-list-section {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 1120px;
  margin: 0 auto;
}

.vacancy-list-section-m {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 100vw;
  margin: 0 auto;
}

.vacancy-list {
  margin-top: 20px;
}

.vacancy-item {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 20px;
  margin: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.vacancy-item h3 {
  font-size: 1.5em;
  color: #0296D2;
}

.vacancy-item p {
  margin: 5px 0;
  color: #666;
}

.vacancy-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.vacancy-links a {
  color: #0296D2;
  text-decoration: none;
  font-weight: bold;
}

.vacancy-links a:hover {
  text-decoration: underline;
}

.status {
  font-weight: bold;
}

.status.on {
  color: green;
}

.status.off {
  color: red;
}

.tableWhite{
  background-color: #ffffff ;
}
.tableGray{
  background-color: #E8E9EB ;
}
.tableHover:hover{
  background-color: #F2F3F5 ;
}
  
.submitButton{
  width: 80px;
  height: 35px;
  border-radius: 8px;
  background:#0296D2;
  color:#FFFFFF;
  border-color: #0296D2;
}

.submitButton:active {
  background: #0296D2;
}


.submit-button {
  background-color: #0296D2;
  border: none;
  color: #fff;
  padding: 16px 32px 16px 32px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-right: -20px;
  height: 52px;
}

.submit-button:hover {
  background-color: #00374E;
}


.card {
  border: 1px solid #ccc;
  padding: 24px;
  height: 104px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  background-color: #F5F5F6;
  border-radius: 8px;
  position: relative;
  border: 0px;
  box-shadow: none;
}
.card-m {
  border: 1px solid #ccc;
  padding: 20px 0px 0px 5px;
  height: auto;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  background-color: #F5F5F6;
  border-radius: 8px;
  position: relative;
  border: 0px;
  box-shadow: none;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-body-m {
  width: 100vw;
}

.card-detail-m {
  border: 1px solid #ccc;
  padding: 24px;
  height: auto;
  margin-top: -20px;
  margin-bottom: -20px;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  position: relative;
  border: 0px;
  box-shadow: none;
}

.header-content {
  flex-grow: 1;
}

.job-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 20px; /* Sesuaikan dengan kebutuhan */
  top: 50%;
  transform: translateY(-50%);
}

.job-action-m {
  display: flex;
  align-items: left;
  position: absolute;
  top: 50%;
  margin-left: 50px;
  transform: translateY(-50%);
}

.job-action-m2 {
  align-items: left;
  margin-left: 50px;
}

.job-type {
  margin-bottom: 0;
  margin-right: 30px; /* Sesuaikan dengan jarak yang diinginkan */
  color: #737E86;
  font-weight: bold;
}

.btn {
  border-radius: 50px; /* Membuat tombol berbentuk oval */
  padding: 8px 16px; /* Menambahkan padding agar tombol lebih besar dan terlihat seperti oval */
  font-size: 12px; /* Sesuaikan ukuran font */
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Aligns pagination to the left */
}

.pagination-btn {
  background-color: transparent; /* No background color for unselected buttons */
  color: #737E86; /* Text color for unselected buttons */
  border: none;
  padding: 8px 16px;
  margin: 2px;
  cursor: pointer;
  border-radius: 50%;
  width: 40px; /* Sets a fixed width */
  height: 40px; /* Sets a fixed height */
  font-size: 16px;
  font-weight: 700;
}

.pagination-btn.active {
  background-color: #0296D2; /* Active button color */
  color: white;
}

.next-page-btn {
  background-color: #F5F5F6; /* Background color for the next page button */
  /* ... other styles ... */
}

.filters {
  display: flex;
  height: 52px;
  align-items: center;
  border-radius: 8px;
  border: 1px;
  padding: 10px;
  box-shadow: 0 8px 64px rgba(2, 150, 210, 0.16);
}

.filter-button {
  background: none;
  border: 0px #e0e0e0;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  color: #737E86;
  width: 160px
}

.filters-m {
  display: flex;
  height: 52px;
  align-items: center;
  border-radius: 8px;
  border: 1px;
  padding: 10px;
  box-shadow: 0 8px 64px rgba(2, 150, 210, 0.16);
  max-width: 73%;
}

.filters-m2 {
  display: flex;
  height: 52px;
  align-items: center;
  border-radius: 8px;
  border: 1px;
  padding: 10px;
  box-shadow: 0 8px 64px rgba(2, 150, 210, 0.16);
  margin-right:20px;
  margin-left:10px;
  max-width: 23%;
}

.filter-button-m {
  background: none;
  border: 0px #e50707;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  color: #0296D2;
  width: 30px
}

.filter-button-mm {
  background: none;
  cursor: pointer;
  color: #0296D2;
  width: 30px;
  display: flex;
  align-items: center;
  border: 0px
}

.filter-icon {
  margin-right: 8px;
  height: 24px;
  width: 24px;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 0px #e0e0e0;
  border-radius: 5px;
  padding: 5px 10px;
  flex-grow: 1;
  height: 52px;
}

.search-bar-cand {
  border-radius: 8px;
  outline-color: #0296D2 ;
  border-width: thin;
  padding: 5px 10px;
}


.search-bar input {
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 5px;
}

.search-button {
  background-color: #0296D2;
  border: none;
  color: #fff;
  padding: 16px 32px 16px 32px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  margin-right: -20px;
  height: 52px;
}

.search-button:hover {
  background-color: #00374E;
}

.applynowdetail-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0296D2;
  border: none;
  color: #fff;
  padding: 32px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  height: 52px;
}

.applynowdetail-button:hover {
  background-color: #00374E;
}

.gap {
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-line {
  width: 1px;
  height: 20px;
  background-color: #E8E9EB;
}

.outerDiv:hover {
  background-color: #eee;
  cursor: pointer;
}

.cand-link {
  color: #0296D2;
  text-decoration: none;
  font-weight: bold;
  text-decoration: underline;
}

.cand-link:hover {
  cursor: pointer;
  color: #00374E !important;
}

.btn-dashb-add-job {
  width: Hug (176px)px;
  height: Hug (40px)px;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 8px;
  opacity: 0px;
  background-color :#1CC8B5;
  color:#FFFFFF;
}

.hoverbtnAdd:hover{
  color:#00374E;
}

.cand-button {
  background: none;
  border: 0px #e7e7e7;
  border-radius: 5px;
  padding: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  background-color: #0296D2;
  color: #FFFFFF;
}

.job-detail-container {
  display: flex;
  margin: 20px;
}

.job-detail-content {
  flex: 2;
  margin-right: 20px;
}

.job-detail-content h1, .job-detail-content h2 {
  margin-top: 0;
}

.hero-content-job {
  position: relative;
  width: 100%;
  height: 240px;
  overflow: hidden;
}

.hero-content-job .background-img {
  width: 100%;
  height: 240px;
  object-fit: cover;
  z-index: 0;
}

.btnHover:hover {
  background-color: #00374E !important;
}

.colorHover:hover {
  color: #00374E !important;
}


.new-job-button {
  background: #1CC8B5;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.new-job-button:hover {
  background: #6ceedf;
}

.buttonHover:hover {
  background: #F5F5F6;
  cursor: pointer;
}


.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.dl-all-cand-button {
  background: #0296D2;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.dl-all-cand-button:hover {
  background: #64a6ed;
}
.container {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.container::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.pdfDiv{
  background-color: #F5F5F6;
  background-image: url('../../public/CandidateDetailBG.png');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto 150px;
  width: 100vw;
  border: 1px solid #E8E9EB;
  margin-top: 50px;
  padding-top: 50px;
}
@media print {
  .pdfDiv {
      width: 100%;
      margin: 0;
      padding: 0;
  }
  .row {
      margin: 0;
  }
}